@media screen and (max-width: 1920px){
	.column-dev-right .develop-table .column-4{
		width: 40%;
	}
	.column-dev-right .develop-table .column-5{
		width: 13%;
	}
}
@media screen and (max-width: 1600px){
	.figure-banner-four{
		top: 0;
		left: 15%;
	}
	.column-dev-right .table-billing .column-3, .column-dev-right .table-billing .column-4{
		width: 30%;
	}
	.column-dev-right .table-keys .label-input{
		width: 23rem;
	}
	.column-dev-right .table-keys .key-input{
		width: 28rem;
	}
}
@media screen and (max-width: 1400px){
	.block-aside-dev__list{
		width: 100%;
	}
}
@media screen and (max-width: 1220px){
	.column-dev-right .table-keys .column-1, .column-dev-right .table-keys .column-2,
	.column-dev-right .table-keys .column-4{
		display: none;
	}
	.column-dev-right .table-keys tr:first-child th:last-child span{
		display: none;
	}
	.column-dev-right .table-keys{
		.label-input{
			margin-bottom: 1.5rem;
			background: none;
			border: none;
			padding: 0;
			color: #8A8E95;
			font-size: 1.4rem;
			height: 2.6rem;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;;
			&:focus{
				background: none;
				border: none;
				padding: 0;
			}
			&::placeholder{
				color: #8A8E95;
			}
		}
		.column-3 a{
			display: block;
		}
	} 
}
@media screen and (max-width: 1200px){
	.index-section__btn{
		margin: 3rem 0 0;
	}
	.blog-index-section .index-section__title::after{
		display: none;
	}
	.content-terms__row{	
		width: 100%;
		margin-left: 0;
	}
	.wrap-block-form{
		width: calc(100% - 2rem);
	}
	.alternativ-section__pay, .alternativ-section__plans{
		flex-direction: column;
		.index-title__h4{
			margin: 0 0 3.5rem;
			text-align: center;
		}
		&-list{
			margin: 0;
		}
	}
	.alternativ-section .wrapper-btn{
		margin-top: 3.5rem;
	}
	.alternativ-section__pay{
		padding: 3.3rem 2.5rem;
	}
	.column-dev-right{
		width: calc(100% - 22.9rem);
	}
	.billing-info-col, .info-plan-right, .info-plan-left .block-checkbox label span{
		width: 100%;
	}
	.billing-form{
		flex-direction: column;
		.left-column,  .right-column{
			width: 100%;
		}
	} 
	.main-header-dev__navigation{
		padding-left: 9%;
	}
	.chage-plan{
		margin-bottom: 2.5rem;
	}
}
@media screen and (max-width: 1100px){
	.dev-section__attention .wrapper-btn{
		text-align: center;
		width: 100%;
		.main-btn{
			margin-top: 2rem;
		}
	}
}
@media screen and (max-width: 1024px){
	.index-section__title{
		font-size: 3.2rem;
		line-height: 5.5rem;
	}
	.main-header__start-btn{
		padding: 1.4rem 2.9rem;
		margin-left: 2.6rem;
	}
	.main-header__navigation-list{
		font-size: 1.2rem;
		&-link{
			padding-left: 1.5rem;
		}
	}
	.main-footer__col .block-logo img{
		width: 10rem;
		height: auto;
	}
	.main-footer__wrap-nav{
		width: 60%;
	}
	.blog-index-section__form{
		display: block;
		&-input{
			margin: 0 2.5rem 3rem 0;
		}
		.error-block{
			bottom: 6rem;
		}
	}
	.blog-index-section .index-video{
		margin-left: 1rem;
	}
	.big-banner-section .figure-banner-three, .big-banner-section .figure-banner-five{
		display: none;
	} 
	.rightSide .wrapper-btn{
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	.rightSide .main-btn:last-child{
		margin: 2rem 0 0;
	}
	.form-success .rightSide img{
		width: 100%;
		height: auto;
	}
	.block-form {
	    width: 43.6rem;
	    padding: 4.8rem 4.3rem;
	}
	.pricing-section__col{
		width: 39rem;
	}
	.pricing-section__row{
		justify-content: space-around;
	}
	.dev-optimized__line-after, .dev-optimized__line-text{
		width: 34%;
	}
	.dev-optimized__line-progress{
		width: 25%;
	}
	.dev-optimized__line-file{
		width: 11rem;
	}
	.api-row{
		flex-wrap: wrap;
	}
	.api-col{
		width: 100%;
	}
}
@media screen and (max-width: 992px){
	.index-section__wrapper-btn{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.main-btn--orange{
			margin: 3rem 0 0;
		}
	}
	.leftSide .index-title__h1{
		font: bold 3.6rem/5.6rem $base-font;
	}
	.leftSide .index-section__paragraph{
		font-size: 1.6rem;
	}
	.leftSide::before{
		display: none;
	}
}
@media screen and (max-width: 800px){
	.main-header{
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		box-shadow: 0rem .4rem .4rem rgba(0, 0, 0, 0.05);
		padding: 0.8rem 0;
		background: white;
		z-index: 100;
		padding: 1rem 0 1.1rem;
		&__start{
			display: none;
		}
		&__hamburger{
			display: block;
		}
		&__start-btn{
			display: none;
		}
	}
	.main-header__navigation, .block-aside-dev{
		background: #fff;
		box-sizing: border-box;
		position: fixed;
		top: 4rem;
		left: 0;
		width: 100%;
		overflow-y: scroll;
		opacity:0;
		transition: all .2s ease-in-out;
		padding: 1.5rem 2rem 0 2rem;
		height: calc(100vh - 4rem);
		z-index: 10;
		pointer-events: none;
		&-list, &__list{
			display: block;
			font-size: 1.6rem;
			&-item{
				border-bottom: .1rem solid rgba(0,0,0,.2);
				&:last-child{
					border-bottom: none;
				}
			}
			&-link{
				display: block;
				text-align: left;
				color: #666;
				padding: 1.65rem 1.5rem 1.65rem 1.5rem !important;
				letter-spacing: .02em;
				font-weight: normal;
				text-transform: uppercase;
				&:hover{
					background: none;
					color: #4B4D51;
				}
			}
		}
		&-btn{
			display: block;
		}
	}
	.menu_active{
		opacity: 1;
		pointer-events: auto;
	}
	.block-aside-dev__list .sub-menu{
		border-top: 0.1rem solid rgba(0,0,0,0.2);
		padding: 0;
		font: 1.6rem/1.9rem $base-font;
		background-color: #f4f4f4;
		&__link{
			color: #4B4D51;
			padding: 1.5rem 1.65rem;
			&:before{
				display: none;
			}
		}
		&__item:not(:last-child){
			margin: 0;
		}
	}
	.block-aside-dev__list .current-item > a{
		background: none;
		color: #4B4D51;
	} 
	.block-aside-dev__list-link{
		font-size: 1.6rem;
		span{
			display: none;
		}
	}
	.main-header__wrap .block-logo{
		height: 2.1rem;
		img{
			width: auto;
			height: 2.1rem;
		}
	}
	.index-section{
		padding-top: 11rem;
		&__title{
			margin-bottom: 3.4rem;
		}
		.wrapper-btn{
			margin-top: 3.5rem;
		}
	}
	.index-row{
		flex-direction: column;
	}
	.index-col{
		width: 100%;
		margin: 0 0 5rem;
	}
	.index-section__paragraph{
		margin-bottom: 4rem;
	}
	.index-target{
		padding: 2.3rem;
	}
	.image-optimizer-section{
		padding: 8rem 0;
		.index-col{
			width: 100%;
			order: 1;
			margin-bottom: 0;
		}
		&__wrap-img{
			margin: 0 0 5rem;
		}
	}
	.features-section{
		padding-bottom: 8rem;
	}
	.reviews-section, .optimized-section{
		padding: 8rem 0;
	}
	.blog-index-section .main-container-post{
		padding: 0rem 2rem 7.7rem;
	}
	.blog-index-section__form{
		width: 46rem;
		margin: 0 auto;
		display: flex;
		&-input{
			margin: 0 2.5rem 0 0;
		}
		.error-block{
			bottom: -1.5rem;
		}
	}
	.blog-index-section .index-video{
		margin: 0;
	}
	.reviews-section__link{
		font: 500 1.8rem/2.6rem $base-font;
		right: 0;
	}
	.content-faq{
		padding-bottom: 6rem;
		&__row{
			flex-direction: column;
		}
	}
	.block-aside{
		order: 0;
		width: 100%;
		margin: 0 0 5rem 0;
		&__list{
			width: 100%;
		}
	}
	.index-section__wrapper-btn{
		flex-direction: row;
		justify-content: center;
		.main-btn--orange{
			margin: 0 0 0 3rem;
		}
	}
	.why-section{
		padding-top: 8rem;
	}
	.testimonials-section{
		padding-top: 0;
	}
	.index-section-form .index-col{
		margin-bottom: 2rem;
	}
	.wrapper-form{
		margin: 0rem auto;
	}
	.block-form{
		margin-left: 0;
	}
	.content-reference{
		padding-bottom: 5.5rem;
		&__row{
			display: flex;
			flex-direction: column;
		}
		.block-aside{
			float: none;
			order: 1;
			width: 100%;
			margin: 1.4rem 0;
			&__list{
				width: 100%;
			}
		}
		&__text{
			border-top: none;
			width: 100%;
			order: 2;
		}
	}
	.block-reference{
		width: 100%;
	}
	.content-article{
		padding-top: 5rem;
		&__img{
			margin: 0 -2rem 5rem;
		}
	}
	.image-optimizer-section .index-row:first-child{
		margin-bottom: 6rem;
	}
	.price-section{
		padding-bottom: 8rem;
	}
	.pricing-section{
		padding: 10rem 0 8rem;
	}
	.price-section__upload, .price-section__calc, .column-dev-right{
		width: 100%;
	}
	.main-container-dev{
		padding: 0 2rem 0;
		.wrapper-h2{
			display: block;
		}
	}
	.column-dev-right{
		padding: 10rem 0 8rem;
	}
	.main-header-dev__navigation{
		padding-left: 0;
		.index-title__h3{
			display: none;
		}
	}
	.block-aside-dev__list .current-item .block-aside-dev__list-link img,
	.block-aside-dev__list-link:hover img{
		filter: brightness(0.7) invert(1);
	}
	.dev-billing-info .wrapper-btn{
		text-align: center;
	}
	.circle-wrap{
		display: none;
	}
}
@media screen and (max-width: 768px){
	.main-footer__col:not(:first-child)::before{
		display: none;
	}
	.features-section__col{
		width: 47%;
	}
	.main-footer__wrap > .main-footer__col{
		display: none;
	}
	.main-footer__wrap{
		flex-direction: column;
		align-items: center;
		&-nav{
			width: 100%;
			margin-bottom: 4rem;
		}
	}
	.block-article{
		padding: 3rem 2% 0;
		width: 46%;
	}
	.company-section{
		padding: 4rem 0 7rem;
	}
	.blog-list-section{
		padding: 8rem 0;
	}
	.content-terms, .content-article{
		padding-bottom: 8rem;
	}
	.content-article__share{
		margin-bottom: 6rem;
	}
	.content-article__date{
		margin-bottom: 4rem;
	}
	.content-article__text .block-navigation{
		padding: 3rem;
		>div{
			padding-right: 4%;
		}
	}
	.optimized-section__line-text{
		width: 41%;
	}
	.optimized-section__line-after{
		width: 22%;
	}
	.optimized-section__line-progress{
		width: 30%;
	}
	.wrap-block-form{
		flex-direction: column;
		padding: 5rem 4rem 22.1rem;
	}
	.leftSide, .rightSide{
		width: 100%;
	}
	.leftSide{
		margin-bottom: 2rem;
	}
	.leftSide::before{
		display: block;
		bottom: -72rem;
	}
	.wrap-block-form .icon-cross{
		right: 2rem;
		top: 2rem;
	}
	.form-success{
		padding: 5rem 4rem;
		.leftSide{
			width: 100%;
		}
		.rightSide{
			width: 60%;
		}
		.wrapper-btn{
			margin-top: 6rem;
		}
	}
	.dev-optimized__line-after{
		width: 34%;
	} 
}
@media screen and (max-width: 640px){
	.index-target-wrap{
		width: 100%;
	}
}
@media screen and (max-width: 600px){
	.optimized-section__form{
		padding: 3rem 3.5rem;
	}
	.optimized-section__line{
		margin: 0 0 1.5rem 0;
		align-items: flex-start;
		padding-bottom: 1.7rem;
		border-bottom: .1rem solid #CECECE;
		&-col{
			flex-direction: column;
			align-items: flex-start;
			width: 78%;
		}
		&-text{
			margin-bottom: .8rem;
			.optimized-section__line-size{
				display: none;
			}
		}
		&-after{
			position: absolute;
			right: 3.5rem;
			width: auto;
			margin-left: 0;
			.optimized-section__line-size{
				display: none;
			}
		} 
		&-progress{
			width: 100%;
		}
		.optimized-section__line-wrap-size svg{
			vertical-align: -.2rem;
		}
		&-file{
			width: 100%;
		}
	}
	.optimized-section__saved{
		margin-top: 3rem;
		border: .2rem dashed #AEAEAE;
		border-radius: 5rem;
	}
	.blog-index-section .index-video{
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 30px;
		height: 0;
		width: 100%;
		iframe{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
	.figure-banner-two, .figure-banner-five{
		display:none;
	}
	.figure-banner-four{
		left: 2%;
		width: 7.7rem;
	}
	.figure-banner-three{
		left: 68%;
		top: 6%;
		width: 4.3rem;
	}
	.figure-banner-one{
		left: 78%;
		bottom: 47px;
		width: 4.1rem;
	}
	.optimized-section__line-text{
		width: 21.2rem;
	}
	.content-article__text .block-navigation{
		padding: 2rem 2rem 1rem;
		flex-direction: column;
		>div >div{
			margin-bottom: 2rem;
		}
	}
	.big-banner-section .figure-banner-three{
		display: block;
	}
	.alternativ-section__plans{
		display: none;
	}
	.alternativ-section__pay-list{
		flex-direction: column;
		li{
			margin: 0 0 .5rem;
		}
	}
	.alternativ-section__pay .wrapper-btn{
		margin-top: 3.5rem;
	}
	.alternativ-section .main-btn{
		padding: 1.4rem 4.3rem;
	}
	.dev-optimized__line-col{
		position: relative;
		width: 100%;
		margin-bottom: 0;
		border-bottom: .1rem solid #DFE3E8;
		padding: 1.5rem 2rem;
		border-radius: 0;
		&:first-child{
			border-radius: .5rem .5rem 0rem 0rem;
		}
		&:last-child{
			border-radius: 0rem 0rem .5rem .5rem;
			border-bottom: none;
		}
	}
	.optimized-section__line-after{
		right: 2.5rem;
	}
	.optimized-section__line-col > .optimized-section__line-wrap-size{
		display: block;
		margin-top: .8rem;
		svg{
			vertical-align: -.2rem;
		}
	}
	.dev-optimized__line-progress{
		width: 80%;
	}
	.column-dev-right__select{
		float: none;
		margin-top: 0;
	}
	.column-dev-right__select .rightSelect, .column-dev-right__select .leftSelect{
		width: 100%;
	}
	.dev-section__attention{
		margin: 0 0 3rem;
	}
	.manual-optimization{
		padding: 2rem;
	}
	.column-dev-right .table-billing .column-1,  .column-dev-right .table-billing .column-4{
		display: none;
	}
	.column-dev-right .table-billing td{
		vertical-align: baseline;
	}
	.column-dev-right .table-billing .column-2 a{
		display: block;
		margin-top: 1.5rem;
	}
	.column-dev-right .table-billing .column-3{
		color: #8A8E95;
	}
	.new-form{
		width: calc(100% - 2rem);
	}
}
@media screen and (max-width: 480px){
	.index-title__h1{
		font: bold 2.6rem/3.5rem $base-font;
		margin-bottom: 2rem;
	}
	.index-section__btn{
		font-size: 2rem;
	}
	.index-target__img{
		width: 12.4rem;
		height: 8.4rem;
		margin: 0 0 2rem 0;
	}
	.index-target__paragraph{
		font: 500 1.4rem/1.7rem $base-font;
	}
	.index-target__paragraph + .index-target__paragraph{
		font: 1.3rem/1.6rem $base-font;
		margin-top: .5rem;
	}
	.index-target-wrap{
		height: auto;
		border-radius: 5rem;
	}
	.index-col{
		margin: 0 0 4rem;
	}
	.main-footer{
		padding: 4rem 0 5rem;
		&__col-list-link{
			font-size: 1.4rem;
		}
		&__col-title{
			margin-bottom: 1.5rem;
			font: 500 1.8rem/2.2rem $base-font;
		}
		.block-logo{
			height: 2rem;
			img{
				width: 10.6rem;
				height: 2rem;
			}
		}
		&__social-link{
			font-size: 1.6rem;
			padding: .8rem;	
		}
		&__social{
			display: flex;
			width: 80%;
			justify-content: space-between;
		}
		&__wrap-nav{
			margin-bottom: 1rem;
		}
	}
	.main-container, .main-container-small{
		padding: 0 2rem;
	}
	.index-section__paragraph{
		margin-bottom: 3rem;
		font: 500 1.6rem/2.3rem $base-font;
	}
	.index-col .main-btn--orange{
		text-align: center;
	}
	.index-section__wrapper-btn{
		text-align: center;
	}
	.index-section{
		padding: 8rem 0 4rem;
	}
	.index-title__h2{
		font: 600 2.2rem/3rem $base-font;
		margin-bottom: 2rem;
	}
	.optimized-section__text{
		font: 400 1.6rem/2.5rem $base-font;
		margin-bottom: 2rem;
	}
	.why-section__text{
		margin-bottom: 3rem;
	}
	.optimized-section{
		padding: 4rem 0;
	}
	.features-section{
		padding: 4rem 0;
		.index-title__h2{
			margin-bottom: 3rem;
		}
		&__col{
			padding: 3rem 0 0;
			width: 100%;
			&-title{
				font-size: 1.8rem;
				margin-bottom: 1.5rem;
			}
			&-text{
				font-size: 1.4rem;
			}
		}
		&__wrapper-btn{
			margin-top: 3rem;
		}
	}
	.image-optimizer-section{
		padding: 5rem 0;
		&__text{
			font: 400 1.6rem/2.5rem $base-font;
		}
		.index-row:first-child{
			margin-bottom: 4rem;
		}
		&__wrap-img img{
			width: 100%;
			height: auto;
		}
	} 
	.our-section__title{
		font: 600 2.2rem/3rem $base-font;
		margin-bottom: 2rem;
	}
	.reviews-section{
		padding: 4rem 0;
		&__text{
			font: 400 1.6rem/2.5rem $base-font;
		}
		&__col{
			margin: 3rem 0 0;
			padding: 3rem;
			&-title{
				font-size: 1.8rem;
			}
			&-text{
				font-size: 1.4rem;
			}
			&-text + &-text{
				margin-top: 1.5rem;
			}
		}
	}
	.features-section__col-img{
		display: block;
		margin-bottom: 3rem;
		width: auto;
		height: 8.6rem;
	}
	.image-optimizer-section__wrap-img img{
		width: 82%;
	}
	.optimized-section__line-text{
		font-size: 1.6rem;
		margin-bottom: 1rem;
	}
	.optimized-section__saved-procent{
		font: 500 2.4rem/2.9rem $base-font;
	}
	.optimized-section__form{
		padding: 3rem 2rem;
	}
	.optimized-section__saved-text{
		font-size: 2rem;
	}
	.optimized-section__saved-procent{
		margin-bottom: 0;
	}
	.optimized-section .main-btn{
		width: 100%;
		margin: 3rem auto 0;
	}
	.optimized-section__line-upload img{
		width: 3.2rem;
		height: auto;
	}
	.optimized-section__saved-wrap > .optimized-section__saved-text{
		display: block;
		text-align: center;
		margin-top: 2rem;
	}
	.optimized-section__saved-economy{
		width: 100%;
		justify-content: space-between;
		flex-direction: row;
	}
	.image-optimizer-section__wrap-img{
		margin: 0 0 3rem;
	}
	.block-article{
		width: 100%;
		padding: 4rem 0 0;
		&__title{
			font: 500 1.8rem/2.2rem $base-font;
		}
	}
	.banner-section .index-title__h2{
		line-height: 3.5rem;
	}
	.blog-list-section{
		padding: 6rem 0;
	}
	.company-section{
		display:none;
	}
	.banner-section{
		padding: 2.7rem 0 0;
	}
	.blog-index-section__form{
		width: 100%;
		display: block;
		&-input{
			width: 100%;
			margin: 0 0 3rem;
		}
		.error-block{
			bottom: 6rem;
			left: 1rem;
		}
	}
	.blog-index-section .index-section__title::before{
		background: url(../img/bg_h1_small.png) no-repeat;
		width: 5.8rem;
		height: 6rem;
	}
	.blog-index-section .main-container-post{
		padding: 0rem 2rem 3rem;
	}
	.blog-index-section .index-col{
		margin: 0 0 6rem;
	}
	.blog-index-section .index-video:before{
		background: url(../img/bg_video_mob.png) no-repeat;
		width: 8rem;
		height: 7.8rem;
		bottom: -6rem;
		left: 0;
	}
	.blog-index-section .index-video:after{
		background: url(../img/bg_video_mob2.png) no-repeat;
		width: 8.3rem;
		height: 7.8rem;
		top: -7rem;
		right: 0;
	}
	.content-text p{
		font: 400 1.6rem/2.5rem $base-font;
	}
	.content-text h3{
		font: 600 1.8rem/2.2rem $base-font;
		margin: 3rem 0 2rem;
	}
	.content-text ul li{
		font: 1.6rem/2.5rem $base-font;
	}
	.content-terms .index-title__h1{
		font: 600 2.2rem/3rem $base-font;
	}
	.reviews-section__link{
		display: none;
	}
	.content-article{
		padding-top: 5rem !important;
	}
	.content-article__img{
		margin: 0 -2rem 3.5rem;
	}
	.content-text ul{
		margin: 0 0 2rem 1rem;
	}
	.content-article__text h3{
		font: 600 2.2rem/3rem $base-font;
	}
	.content-article__text h4{
		font: 500 1.8rem/2.5rem $base-font;
		margin: 2rem 0 1rem;
	}
	.content-article__text form{
		margin: 4rem 0;
		padding: 4rem 3rem;
		input{
			width: 100%;
		}
		.error-block{
			left: 3.5rem;
		}
	}
	.content-text figure{
		margin: 4rem 0;
		figcaption{
			font-size: 1.4rem;
		}
		img{
			margin-bottom: 1rem;
		}
	} 
	.content-text blockquote{
		margin: 4rem 0;
		padding: 2.5rem 0rem;
		>p{
			font: 600 italic 2.2rem/3rem $base-font;
			margin-bottom: 2rem;
		}
		p+p{
			font: 500 1.8rem/2.6rem $base-font;
			margin-bottom: 0rem;
		}
		span{
			font-size: 1.4rem;
		}
	}
	.content-text .img-format{
		margin-bottom: 2rem;
		flex-direction: column;
		img{
			margin-right: 0;
		}
		div:last-child{
			font: 500 1.6rem/2.5rem $base-font;
		}
	}
	.content-text .img-affect{
		margin: 4rem 0;
	}
	.content-article__text{
		margin-bottom: 3rem;
	}
	.content-article__posts-h3{
		font: 600 2.2rem/3rem $base-font;
	}
	.content-article__posts-list-link{
		font: 1.6rem/2.5rem $base-font;
	}
	.content-article__text > .block-navigation > div > div a{
		font-size: 1.4rem;
	}
	.content-article__text > .block-navigation > div > div{
		margin-bottom: 1.5rem;
		&:not(:last-child){
			margin-bottom: 1.5rem;
		}
	}
	.owl-carousel.owl-drag .owl-item{
		margin-top: 3rem;
	}
	.error-block{
		font-size: 1.2rem !important;
	}
	.content-404__text{
		font: 500 1.8rem/2.2rem $base-font;
		margin-bottom: 1.5rem;
	}
	.content-404 .index-title__h1{
		font: 600 2.2rem/3rem $base-font;
		margin-bottom: 4rem;
	}
	.content-404__menu-text{
		font: 500 1.6rem/2.3rem $base-font;
		margin-bottom: 1.5rem;
	}
	.content-404__menu-list-link{
		font-size: 1.4rem;
	}
	.block-faq__list{
		margin-bottom: 3rem;
		&-link{
			font: 1.6rem/2.8rem $base-font;
			margin-bottom: 1rem;
		}
	}
	.content-reference .block-aside, .content-faq .block-aside{
		margin: 0 0 3rem 0;
		&__list{
			position: relative;
			padding-top: 5rem;
			border: 0.1rem solid rgba(177,182,189,0.6);
			box-shadow: none;
			width: 100%;
			&:not(:last-child){
				border-bottom: none;
			}
			.current-item{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				background: #fff;
				&:before{
					content: '';
					position: absolute;
					top: 50%;
					right: 2rem;
					margin-top: -.3rem;
					background: url(../img/arrow_dropdown.png) no-repeat;
					width: 1.1rem;
					height: .6rem;
				}
				a{
					color: #4B4D51;
					background: white;
				}
			}
			&-item{
				overflow: hidden;
				box-sizing: border-box;
				&:not(.current-item){
					max-height: 0;
					box-sizing: border-box;
					transition: max-height .5s;
					overflow: hidden;
				}
			}
			&-link{
				line-height: 5rem;
				padding: 0 1.4rem;
				font-size: 1.6rem;
				&:hover{
					background: white;
					color: #4B4D51;
				}
			}
		}
		.active  .block-aside__list-item:not(:last-of-type){
			border-bottom: .1rem solid #e6e4e4;
		}
		.active  .block-aside__list-item{
			max-height: 5rem !important;
		}
		.active .current-item:before{
			transform: rotate(180deg);
		}
	}
	.content-faq__text h3{
		margin: 2rem 0 1rem;
	}
	.content-faq__text figure, .content-faq__text blockquote{
		margin: 3rem 0;
	}
	.content-faq__text ul li, .content-faq__text p{
		margin-bottom: 1rem;
	}
	.index-target{
		padding: 2.7rem;
		flex-direction: column;
	}
	.index-target-wrap{
		margin-top: 1rem;
	}
	.main-section__row .index-section__title{
		font-size: 2.6rem;
		text-align: left;
	}
	.optimized-section__saved{
		margin-top: 1.5rem;
		&-text{
			display: none;
		}
	}
	.optimized-section__saved div:not(:last-child){
		margin-right: 2rem;
	}
	.why-section__col-title{
		min-height: auto;
	}
	.why-section .features-section__col-img{
		width: 10.5rem;
		height: 9rem;
	}
	.features-section .index-title__h2{
		margin-bottom: 2rem;
	}
	.index-section__wrapper-btn{
		flex-direction: column;
		.main-btn--orange{
			margin: 2rem 0 0;
		}
	}
	.big-banner-section .optimized-section__text{
		font: 1.4rem/2.5rem $base-font;
	}
	.big-banner-section__text{
		font: 500 1.6rem/2.3rem $base-font;
		margin-bottom: 3rem;
	}
	.optimized-section__line-after{
		right: 2rem;
	}
	.leftSide .index-section__paragraph{
		display: none;
	}
	.leftSide .index-title__h1{
		font: 600 2.2rem/3rem $base-font;
		text-align: center;
	}
	.wrap-select{
		display: block;
	}
	.leftSelect, .rightSelect{
		margin-right: 0;
		width: 100%;
	}
	.form-select{
		background-position: 96% 51%;
	}
	.wrap-block-form{
		padding: 5rem 2rem 29rem;
	}
	.leftSide::before{
		display: none;
	}
	.wrap-block-form::before{
		background: url(../img/img_form_mobile.png) no-repeat;
		bottom: -2.7rem;
		left: auto;
		right: -7.8rem;
	}
	.wrap-block-form .icon-cross{
		font-size: 1.4rem;
	}
	.optimized-section__col-title{
		font: 1.4rem/1.7rem $base-font;
	}
	.web-section{
		padding: 2rem 0;
	}
	.main-footer{
		padding: 4rem 0 6rem;
		&__col{
			width: 50%;
			margin-bottom: 3rem;
			padding: 0 !important;
			&-list-item:hover ul{
				display: block;
			}
			&-list-span{
				font-size: 1.6rem;
				&:after{
					top: 2.2rem;
				}
			}
		}
		&__wrap-nav{
			margin-bottom: 3rem;
			flex-wrap: wrap;
		}
		.main-container{
			padding: 0 3.5rem;
		}
	}
	.language-list{
		padding: 1rem 1.5rem;
		width: 100%;
		&__item{
			width: 100%;
		}
		&__link{
			font-size: 1.6rem;
		}
	}
	.form-success{
		padding: 5rem 2rem;
		&__list-link{
			font-size: 1.6rem;
		}
		.rightSide{
			width: 90%;
		}
		.wrapper-btn{
			margin-top: 4rem;
		}
		.leftSide .index-section__paragraph{
			display: block;
		}
	}
	.index-section-form .index-col{
		display: none;
	}
	.block-form{
		box-shadow: none;
		padding: 0;
		width: 100%;
		&__title{
			margin-bottom: .5rem;
			font: bold 2.4rem/3.5rem $base-font;
		}
		&__text{
			margin-bottom: 2.5rem;
			font-size: 1.4rem;
			line-height: 2.6rem;
		}
		&__col{
			margin-bottom: 1.5rem;
			&-text{
				font-size: 1.4rem;
			}
		}
		&__p{
			font-size: 1.4rem;
		}
	}
	.block-form__wrap-btn{
		padding: 2rem 0 1rem;
		.main-btn--orange{
			padding: 1rem 4rem;
			font-size: 1.4rem;
		}
	} 
	.block-forgot{
		margin: -0.5rem 0 0;
	}
	.content-reference__text p, .content-reference__paragraph{
		font: 1.4rem/2.5rem $base-font;
	}
	.content-reference__text p:last-child{
		margin-bottom: 0;
	}
	.content-reference__text ul{
		margin: 0 0 3rem 0;
		padding: .8rem 1rem .8rem 1.4rem;
		li{
			font-size: 1.1rem;
		}
	}
	.content-reference .index-title__h1{
		margin-bottom: 3rem;
	}
	.content-reference .block-aside{
		margin: .8rem 0;
	}
	.content-reference .block-aside__list-link{
		padding: 0 1.4rem;
	}
	.started-section{
		padding: 4rem 0 0;
	}
	.developer-section .index-title__h1 + .index-section__paragraph{
		margin-bottom: 1rem;
	}
	.price-section{
		padding: 1rem 0 5rem;
	}
	.main-container-pricing{
		padding: 0 1rem;
	}
	.pricing-section__col{
		width: 31rem;
		margin: 3rem 0 0;
		padding: 1.8rem 2.7rem;
		&-save, &:first-child, &:last-child{
			display: none;
		}
		&-price{
			margin-bottom: 2.5rem;
			span{
				font: bold 2.6rem/4.5rem $base-font;
				vertical-align: -.3rem;
			}
		}
		&-month{
			margin: 0 auto .6rem;
		}
		&-list{
			margin-bottom: 2.5rem;
		}
		.main-btn{
			margin-bottom: 1.5rem;
		}
	}
	.pricing-section__text{
		font: 500 1.8rem/2.2rem $base-font;
	}
	.pricing-section{
		padding: 8rem 0 4rem;
	}
	.alternativ-section{
		padding: 0rem 0 7rem;
		&__h3{
			margin-bottom: 4rem;
			font: 600 2.2rem/3rem $base-font;
		}
	}
	.alternativ-section__pay .wrapper-btn{
		margin-top: 0;
	} 
	.price-section__upload{
		margin-top: 0rem;
		&-title{
			font: 600 1.6rem $base-font;
		}
	}
	.price-section__list{
		padding: .5rem 2rem 0rem;
		font: 1.4rem/2.4rem $base-font;
		&-item{
			padding: .8rem 0;
			font: 1.4rem/2.4rem $base-font;
		}
	}
	.price-section__calc{
		margin-top: 2rem;
		&-title{
			font: 600 1.6rem/3.5rem $base-font;
		}
		&-compres, &-total{
			font: 1.4rem/3.5rem $base-font;
		}
		&-prices{
			width: 100%;
			margin-bottom: 2.5rem;
		}
		&:after{
			display: none;
		}
		&:before{
			top: 12.2rem;
		}
		&-prices{
			height: 1.8rem;
			.ui-slider-handle{
				border: .6rem solid #FFF;
				width: 1.4rem;
				height: 1.4rem;
				margin-left: -1.4rem;
			}
		}
	}
	.dev-optimized__line-progress{
		width: 78%;
	}
	.optimized-section__line-after{
		top: 4rem;
	}
	.block-question{
		padding: 1.7rem 2rem;
		.index-title__h5{
			font-size: 1.6rem;
		}
		&__text{
			font-size: 1.4rem;
			margin-top: 1rem;
		}
	}
	.column-dev-right{
		padding: 7rem 0 8rem;
		.index-title__h3{
			font: 500 1.8rem/2.2rem $base-font;
		}
	} 
	.column-dev-right__p{
		font-size: 1.4rem;
	}
	.dev-section__attention-text{
		padding-left: 0;
		&:before{
			display: none;
		}
	}
	.dev-section__attention{
		padding: 1.3rem 1.5rem;
		.index-title__h5, p{
			font-size: 1.4rem;
			line-height: 2rem;
			margin-bottom: .5rem;
		}
		.wrapper-btn .main-btn{
			margin-top: 1rem;
			padding: 1.2rem 2.4rem;
		}
	} 
	.dev-target__text{
		font-size: 1.4rem;
	}
	.manual-optimization .active .manual-optimization__list-item{
		max-height: 5rem !important;
		&:not(:last-of-type){
			border-bottom: .1rem solid #e6e4e4;
		}
	}
	.manual-optimization .active .item-active:before{
		transform: rotate(180deg);
	}
	.manual-optimization__list{
		position: relative;
		padding-top: 4rem;
		box-shadow: 0 .1rem .3rem rgba(63, 63, 68, 0.15), 0 0 0 rgba(63, 63, 68, 0.05);
		border-radius: .3rem;
		width: 100%;
		.item-active{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 20;
			width: 100%;
			background: #fff;
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				right: 2rem;
				margin-top: -.3rem;
				background: url(../img/arrow_dropdown.png) no-repeat;
				width: 1.1rem;
				height: .6rem;
				z-index: 10;
			}
		}
		&-link{
			line-height: 4rem;
			padding: 0 1.4rem;
			font-size: 1.4rem;
			color: #4B4D51;
			&:before{
				display: none;
			}
		}
		&-item:not(.item-active){
			max-height: 0;
			width: 100%;
			box-sizing: border-box;
			transition: max-height .5s;
			overflow: hidden;
		}
		&-item:not(:last-child){
			margin-right: 0;
		}
	}
	.column-dev-right .table-dev th, .column-dev-right .table-dev td{
		padding: 2.1rem 2rem 2.1rem 2rem;
	}
	.column-dev-right .table-billing .column-3{
		width: 24%;
	}
	.api-col{
		margin-top: 3rem;
		&__text{
			font-size: 1.4rem;
		}
	}
	.column-dev-right .wrap-table{
		margin-bottom: 3rem;
	}
	.column-dev-right .table-dev .column-general{
		font-size: 1.4rem;
	}
	.key-btn, .api-col .main-btn{
		width: 100%;
		text-align: center;
		padding: 1.4rem;
	}
	.new-form .new-form__top, .new-form .new-form__center, .new-form .wrapper-btn{
		padding: 1.5rem;
	}
	.new-form .close-popup{
		right: 2rem;
		top: 2.2rem;
	}
	.new-form .wrapper-btn .main-btn{
		width: 100%;
		text-align: center;
		&:not(:last-child){
			margin: 0 0 1.5rem;
		}
	}
	.billing-info-col{
		font-size: 1.4rem;
		padding: 1.6rem 1.7rem 2rem;
		&__data{
			margin-bottom: 1rem;
		}
		.wrap-title{
			margin-bottom: 1.5rem;
		}
		.main-btn{
			padding: 1.4rem 3.3rem;
			width: 100%;
			text-align: center;
		}
	}
	.table-payment{
		margin-bottom: 1.5rem;
		&__block{
			font-size: 1.4rem;
			padding: 1.3rem 1.5rem;
		}
		&__link{
			display: none;
		}
	}
	.rightSide .wrapper-btn{
		margin-top: 0;
	}
	.billing-form__wrap{
		width: 100%;
	}
	.dev-billing-info .main-btn{
		width: 100%;
	}
	.dev-billing-info .main-btn--orange{
		margin: 0rem 0 2rem;
	}
	.dev-billing-info .block-checkbox__title, .billing-form__text, .billing-form .label-form{
		font-size: 1.4rem;
	}
	.billing-wrap__price{
		position: inherit;
		display: inline-block;
		margin-bottom: 1rem;
	}
	.billing-wrap__account{
		display: none;
	}
}
@media screen and (max-width: 440px){
	.content-404__img{
		width: 100%;
		height: auto;
	}
}
@media screen and (max-width: 380px){
	.reviews-section__col{
		width: 100%;	
	}
	.main-footer__col{
		width: 11rem;
	}
	.optimized-section__line-text{
		width: 19rem;
	}
	.language-list{
		width: 17rem;
		right: 0;
	}
}
@media screen and (max-width: 320px){
	.index-section{
		padding-top: 8rem;
		&__btn{
			margin-top: 2rem;
		}
		.wrapper-btn{
			margin-top: 3rem;
		}
		&__title{
			font: 900 2.6rem/3.2rem $base-font;
		}
	}
	.optimized-section__saved-text{
		font: 500 1.4rem/1.7rem $base-font;
	}
	.banner-section .main-container-post{
		padding: 5rem 2rem;
		background: url(../img/bg_banner_mob.jpg) no-repeat;
	}
	.blog-index-section{
		padding-bottom: 0;
		.main-container-post{
			background: none;
			padding-bottom: 7rem;
			background: url(../img/bg_posts_mob.jpg) center no-repeat;
			background-color: #FFD3B9;
		}
	}
	.big-banner-section .main-container-post{
		background: #FFD3B9;
	}
}




