@mixin back-circle(){
	content: '';
	position:absolute;
	width: 74.3rem;
	height: 74.3rem;
	background: #3B8CE9;
	z-index: 0;
	border-radius: 50%;
}
@mixin container(){
	position: relative;
	width: 100%;
	margin: 0 auto;
	z-index: 3;
	box-sizing: border-box;
}
@mixin bg-banner{
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: #FFD3B9;
	top: 0;
}
@mixin bg-video{
	content: '';
	position: absolute;
	bottom: 0;
	z-index: -1;
}
@mixin bg-h1{
	content: '';
	position: absolute;
	z-index: -1;
}
@mixin disk-ul{
	content: '';
	position: absolute;
	left: 0;
	width: .5rem;
	height: .5rem;
	background: #4B4D51;
	border-radius: 50%;
}
@mixin border-reference{
	background: #F9F9F9;
	border: .1rem solid #D9E8ED;
	box-sizing: border-box;
	border-radius: .4rem;
}
@mixin wrap-dev{
	box-sizing: border-box;
	background: white;
	box-shadow: 0 .1rem .3rem rgba(63, 63, 68, 0.15), 0 0 0 rgba(63, 63, 68, 0.05);
	border-radius: .5rem;
}