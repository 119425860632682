$font-path : '../fonts/';

@font-face {
  font-display: swap;
  font-family: 'Inter';
  src:  url('#{$font-path}Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),  
        url('#{$font-path}Inter/Inter-Regular.woff') format('woff'), 
        url('#{$font-path}Inter/Inter-Regular.ttf')  format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  src:  url('#{$font-path}Inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),  
        url('#{$font-path}Inter/Inter-Medium.woff') format('woff'), 
        url('#{$font-path}Inter/Inter-Medium.ttf')  format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  src:  url('#{$font-path}Inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),  
  		  url('#{$font-path}Inter/Inter-SemiBold.woff') format('woff'), 
  		  url('#{$font-path}Inter/Inter-SemiBold.ttf')  format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  src:  url('#{$font-path}Inter/Inter-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),  
        url('#{$font-path}Inter/Inter-SemiBoldItalic.woff') format('woff'), 
        url('#{$font-path}Inter/Inter-SemiBoldItalic.ttf')  format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  src:  url('#{$font-path}Inter/Inter-Bold.eot?#iefix') format('embedded-opentype'),  
        url('#{$font-path}Inter/Inter-Bold.woff') format('woff'), 
        url('#{$font-path}Inter/Inter-Bold.ttf')  format('truetype');
  font-weight: bold;
  font-style: normal;
}

$font-proxima: 'ProximaNova', sans-serif;
$base-font: 'Inter', sans-serif;
