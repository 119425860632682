html{
	font-size: 62.5%;
	line-height: 1.5;
}
body, html{
	height: 100%;
}
.main-wrapper{
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	font: 1.6rem $base-font;
	color: #1E1D2F;
	background: white;
}
.main-wrapper-404{
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.main-container-small{
	@include container();
	max-width: 121rem;
	padding: 0 2rem;
}
.main-container{
	@include container();
	max-width: 138rem;
	padding: 0 2rem;
}
.main-container-dev{
	@include container();
	padding: 0 4rem;
	.wrapper-h2{
		display: none;
		h2{
			text-align: left;
			margin-bottom: 2rem;
		}
	}
}
.main-container-post{
	@include container();
	max-width: 151rem;
	padding: 0 2rem;
}
.main-container-article{
	@include container();
	max-width: 139rem;
	padding: 0 2rem;
}
.main-container-pricing{
	@include container();
	max-width: 131rem;
	padding: 0 2rem;
}
.big-container{
	position: relative;
	padding: 9.5rem 0 6rem;
	max-width: 150rem;
	margin: 2.5rem auto 0;
	box-sizing: border-box;
	min-height: 60rem;
	&__img{
		position:absolute;
		top: 0;
	}
}
.index-title__h1{
	position: relative;
	font: bold 4.2rem/6.5rem $base-font;
	margin-bottom: 3rem;
}
.index-title__h2{
	text-align: center;
	font: 600 3.6rem/5rem $base-font;
	margin-bottom: 5rem;
}
.index-title__h3{
	font: 600 2.4rem/3.6rem $base-font;
}
.index-title__h4{
	font: 500 2.2rem/2.6rem $base-font;
}
.index-title__h5{
	font: 500 1.8rem/2.2rem $base-font;
	color: #4B4D51;
}
.main-header{
	background: #FAFAFA;
	padding: 2.6rem 0 6rem;
	&__navigation-list{
		display: flex;
		justify-content: space-between;
		font: 600 1.4rem/1.7rem $base-font;
		&-link{
			position: relative;
			padding-left: 2.5rem;
			color: #1E1D2F;
			text-transform: uppercase;
			&:hover{
				color: #FF8314;
			}
		}
		&-item:first-child &-link{
			padding-left: 0rem;
		}
	}
	&__navigation-wrap{
		display: flex;
		align-items: center;
	}
	&__navigation-btn{
		display: none;
		background: #FF8314;
		width: 100%;
		text-align: center;
		padding: 1.4rem 0;
		font: 600 1.6rem $base-font;
		color: white;
		position: absolute;
		left: 0;
		cursor: pointer;
	}
}
.main-header--other{
	padding: 2.6rem 0;
	background: #FFFFFF;
}
.main-header__wrap{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.block-logo{
	display: inline-block;
	cursor: pointer;
}
.main-header__start-btn{
	margin-left: 3.5rem;
}
.main-header__start-link{
	color: #292B34;
	font-weight: bold;
}
.main-header__hamburger{
	position: relative;
	z-index: 100;
	display: none;
	padding: .8rem;
	margin-right: -.8rem;
	cursor: pointer;
	&-line{
		width: 2rem;
		height: .3rem;
		background: #FF8314;
		margin-bottom: .2rem;
		border-radius: .5rem;
		display: block;
		overflow: hidden;
		transition: opacity .35s ease-in,transform .35s ease-in;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
/*----------index-section-------------*/
.index-section{
	background: #FAFAFA;
	padding: 0 0 8rem;
	&__wrapper-btn .main-btn--orange{
		margin-left: 3rem;
	}
}
.index-row{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.index-col{
	width: 47.5%;
}
.index-img img{
	width: 100%;
	height: auto;
}
.index-section__paragraph{
	color: #8A8E95;
	font: 500 1.8rem/3rem $base-font;
	margin-bottom: 5.8rem;
}
.index-section__btn{
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #FFC002;
	font: bold 2.4rem $base-font;		
	letter-spacing: 0.004em;
	margin-left: 5.2rem;
	&:hover .block-circle{
		background: #FFC002;
		.icon-play{
			color: white;			
		}	
	}
}
.index-target-wrap{
	position: relative;
	cursor: pointer;
	border: .2rem dashed #AEAEAE;
	box-sizing: border-box;
	border-radius: 1.5rem;
	width: 100%;
}
.index-target{
	display: flex;
	align-items: center;
	padding: 3.3rem 4.9rem;
	&__paragraph{
		font: 500 2.2rem/2.6rem $base-font;
	}
	&__paragraph + &__paragraph{
		font: 1.4rem/1.7rem $base-font;
		margin-top: 1.2rem;
	}
	&__img{
		margin-right: 3rem;
	}
	&__input{
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		cursor: pointer;
	}
}
/*------------main section-----------*/
.main-section__row{
	flex-direction: column;
	width: 72.5rem;
	margin: 0 auto;
	max-width: 100%;
	@-webkit-keyframes animate-stripes { 
		0% {background-position: 0 0;} 100% {background-position: 60px 0;}
    }          
    @-moz-keyframes animate-stripes {
		0% {background-position: 0 0;} 100% {background-position: 60px 0;}
    }		
	.main-btn{
		margin: 3rem auto 0rem;
		display: inline-block;
		align-items: center;
		.icon{
			font-size: 2rem;
			margin-right: 1rem;
			vertical-align: -.4rem;
		}
	}
} 
.progress-now span{
	display: inline-block;
    height: 100%;
	background-color: #777;
	transition: width .4s ease-in-out;	
	border-radius: 28px; 	
	width: 100%;
	background-color: #57B892;  
}
.stripes span{
	background-size: 30px 30px;			
    background-image: linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%,transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,transparent 75%, transparent);            
    -webkit-animation: animate-stripes 3s linear infinite;
    -moz-animation: animate-stripes 3s linear infinite;   
}
.main-section__row .index-section__title{
	font-size: 3.6rem;
	text-align: center;
	span{
		color: #E33B21;
	}
}
.progress-error{
	background: #E23424;
}
.progress-success{
	background: #57B892;
}
.progress-waiting{
	background: white;
	border: 1px solid #8A8E95;
	span{
		color: #8A8E95;
	}
}
/*-----------our section ----------------*/
.our-section__title{
	font: 600 2.4rem/3.5rem $base-font;	
	margin-bottom: 4.5rem;
}
/*--------optimized section--------------*/
.optimized-section{
	padding: 8rem 0 13rem;
	background: #FFFFFF;
	&__row{
		width: 77rem;
		max-width: 100%;
		margin: 0 auto;
	}
	&__text{
		text-align: center;
		font: 400 1.8rem/3.5rem $base-font;
		color: #8A8E95;
		margin-bottom: 3rem;
	}
	&__wrap-form{
		display: none;
		text-align: center;
		margin: 3rem auto 0rem;
		width: 100%;
	}
	&__form{
		position: relative;
		padding: 3rem 3.5rem 0;
		box-sizing: border-box;
		border: .1rem solid #CECECE;
		border-radius: 1.5rem;
	}
	&__line{
		display: flex;
		justify-content: space-between;
		margin-bottom: 3rem;
		&-progress{
			width: 23.7rem;
			height: 2.4rem;
			border-radius: 2.8rem;
			text-align: center;
			color: white;
			font: 500 1.4rem/2.3rem $base-font;
		}
		&-file{
			width: 65%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&-col{
			display: flex;
			align-items: center;
			width: 100%;
			>.optimized-section__line-wrap-size{
				display: none;
			}
		}
		&-size{
			font: 500 1.4rem/2.3rem $base-font;
			color: #8A8E95;
			text-align: center;
		}
		&-after{
			display: flex;
			align-items: center;
			margin-left: 2rem;
			justify-content: space-between;	
			width: 22%;
		}
		&-text{
			font: 600 1.8rem/2.2rem $base-font;
			text-align: left;
			display: flex;
			justify-content: space-between;
			width: 35%;
			margin-right: 2rem;
		}
	}
	&__saved-wrap >.optimized-section__saved-text{
		display: none;
	}
	&__saved{
		display: flex;
		align-items: center;
		box-sizing: border-box;
		justify-content: center;
		border-top: .1rem solid #CECECE;
		padding: 2rem 0;
		margin-top: 4.5rem;
		div:not(:last-child){
			margin-right: 3rem;
		}
		&-text{
			font: 500 2.2rem/2.6rem $base-font;
			text-align: left;
		}
		&-procent{
			font: 500 3.6rem/4.3rem $base-font;	
		}
		&-size{
			font: 500 1.4rem/1.7rem $base-font;
		}
	}
	&__col-title{
		font: 1.8rem/2.2rem $base-font;
	}
	&__col-img{
		margin-bottom: 1.5rem;
		width: 100%;
		height: auto;
	}
}
/*----features section--------------*/
.features-section{
	background: white;
	padding-bottom: 13rem;
}
.features-section__row{
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
 	width: 121.2rem;
	max-width: 100%;
}
.features-section__col{
	width: 30.5%;
	padding: 3rem 1.4% 0;
	&-img{
		margin-bottom: 3rem;
	}
	&-title{
		font: 500 2.2rem/2.6rem $base-font;
		margin-bottom: 2.5rem;
	}
	&-text{
		font-weight: 400;
		line-height: 2.5rem;
		color: #8A8E95;
	}
}
/*-------------image optimizer section-----------------*/
.image-optimizer-section{
	background: #FAFAFA;
	padding: 13rem 0;
	&__text{
		font: 400 1.8rem/3rem $base-font;
		color: #8A8E95;
	}
	.index-row{
		align-items: flex-start;
		width: 101rem;
		max-width: 100%;
		margin: 0 auto;
		&:first-child{
			margin-bottom: 10rem;
			.index-col{
				order: 1;
			}
		}
	}
	.index-col{
		width: 58%;
	}
}
/*------------reviews-section-------------------*/
.reviews-section{
	padding: 13rem 0;
	&__link{
		position: relative;
		font: 500 2.2rem/2.6rem $base-font;
		position: absolute;
		color: #FF8314;
		padding-right: 2rem;
		right: 0;
		top: 0.5rem;
		&:hover{
			text-decoration: underline;
		}
		.arrow-right{
			vertical-align: -.2rem;
		}
	}
	&__text{
		font: 400 1.8rem/3rem $base-font;
		color: #8A8E95;
	}
	&__row{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}
	&__col{
		width: 31.2rem;
		margin: 5.5rem 1.1rem 0;
		box-sizing: border-box;
		box-shadow: .1rem .4rem 2rem rgba(218, 218, 218, 0.45);
		border-radius: 5rem;
		padding: 4rem;
		min-height: 100%;
		&-title{
			font: 500 2.2rem/2.6rem $base-font;
			margin-bottom: 1rem;
		}
		&-rating{
			margin-bottom: 2rem;
		}
		&-text{
			line-height: 2.5rem;
			color: #8A8E95;
		}
		&-text + &-text{
			margin-top: 2.5rem;
		}
	}
}
/*--------------------------------*/
.main-footer{
	position: relative;
	z-index: 20;
	background: #F8F8F8;
	padding: 6.3rem 0 5.5rem;
	&__wrap{
		display: flex;
		justify-content: space-between;
	}
	&__wrap-nav{
		display: flex;
		width: 65.2rem;
		justify-content: space-between;
	}
	&__col{
		position: relative;
		&-title{
			font: 500 2.2rem/2.6rem $base-font;
			margin-bottom: 2rem;
		}
		&-list-link{
			display: inline-block;
			width: 100%;
			color: #8A8E95;
			padding: 0.8rem 0;
			&:hover{
				color: #FF8314;
			}
		}
		&-list-item:first-child &-list-link{
			padding-top: 0;
		}
		&-list-item{
			position: relative;
			&:hover ul{
				display: flex;
			}
		}
		.language-item{
			margin-top: .8rem;
		}
		&-list-span{
			position: relative;
			cursor: pointer;
			font: 500 1.8rem/2.2rem $base-font;
			padding: 1.5rem 2rem 0 2.6rem;
			&:before{
				content: '';
				position: absolute;
				left: 0;
				top: 1.7rem;
				background: url(../img/icon_lang.svg) no-repeat;
				width: 1.8rem;
				height: 1.8rem;
			}
			&:after{
				content: '';
				position: absolute;
				right: 0;
				top: 2.4rem;
				background: url(../img/arrow_lang.svg) no-repeat;
				width: 1.4rem;
				height: 1.2rem;
			}
		}
	}
	&__social-link{
		padding: 1rem;
		font-size: 2rem;
		color: #1E1D2F;
		&:hover{
			color: #FF8314;
		}
	}
	.block-logo{
		margin-bottom: 3rem;
	}
}
.language-list{
	position: absolute;
	bottom: 3rem;
	display: none;
	flex-wrap: wrap;
	right: 1rem;
	justify-content: space-between;
	width: 41rem;
	background: #FFFFFF;
	box-sizing: border-box;
	padding: 1.6rem 3rem;
	box-shadow: .1rem .4rem 4rem rgba(218, 218, 218, 0.45);
	border-radius: 1.5rem;
	&__link{
		display: block;
		padding: 0.8rem 0;
		font: 500 1.8rem/2.2rem $base-font;
		color: #1E1D2F;
		&:hover{
			color: #FF9E55;
		}
	}
	&__item{
		width: 46%;
	}
}
.error-block{
	font: 500 1.4rem/1.7rem $base-font;
}
.blog-index-section{
	background-color: #FFD3B9;
	padding-bottom: 0;
	.main-container-post{
		background: url(../img/bg_posts.jpg) center no-repeat;
		padding: 7.7rem 2rem;
	}
	.index-row{
		width: 117rem;
		max-width: 100%;
		margin: 0 auto;
	}
	.index-video{
		position: relative;
		margin-left: 6rem;
		&:before{
			@include bg-video();
			background: url(../img/bg_video.png) no-repeat;
			width: 10.5rem;
			height: 12.8rem;
			left: -7.8rem;
		} 
		&:after{
			@include bg-video();
			background: url(../img/bg_video2.png) no-repeat;
			width: 12.2rem;
			height: 14.4rem;
			right: -9.5rem;
		}
	}
	.index-section__paragraph{
		color: #626468;
	}
	&__form{
		position: relative;
		display: flex;
		&-input{
			padding-left: 2rem;
			font: 500 1.6rem $base-font;
			width: 27.2rem;
			box-sizing: border-box;
			border: 1px solid #E0E0E0;
			height: 4.4rem;
			border-radius: 6.5rem;
			margin-right: 2.5rem;
		}
		&:before{
			content: '';
			position: absolute;
			background: url(../img/bg_input.png) no-repeat;
			width: 6.7rem;
			height: 6.8rem;
			left: -4rem;
			top: -2.5rem;
			z-index: -1;
		}
		.error-block{
			position: absolute;
			font: 500 1.4rem/1.7rem $base-font;
			bottom: -1.5rem;
			left: 1.5rem;
		}
	}
	.index-section__title{
		&:before{
			@include bg-h1();
			background: url(../img/bg_h1.png) no-repeat;
			width: 11.6rem;
			height: 11.9rem;
			right: 0;
			top: -1rem;
		}
		&:after{
			@include bg-h1();
			background: url(../img/bg_h1_2.png) no-repeat;
			left: -8rem;
			top: -5rem;
			width: 7.3rem;
			height: 6.3rem;
		}
	}
}
.blog-list-section{
	padding: 13rem 0;
}
.block-articles{
	display: flex;
	flex-wrap: wrap;
	width: 120rem;
	max-width: 100%;
	margin: 0 auto;
}
.block-article{
	padding: 3rem 1.25% 0;
	width: 30.8%;
	&__date{
		display: block;
		font: 400 1.4rem/1.7rem $base-font;
		color: #727B9D;
		margin-bottom: 1.5rem;
	}
	&__link{
		display: block;
		&-img{
			width: 100%;
			height: auto;
			margin-bottom: 1.5rem;
		}
		&:hover .block-article__title{
			color: #FF8314;
		}
	}
	&__title{
		display: block;
		font: 500 2.2rem/2.6rem $base-font;
		color: #1E1D2F;
		margin-bottom: 1.5rem;
	}
	&__btn{
		display: inline-block;
		font: 500 1.8rem/2.2rem $base-font;
		color: #FF8314;
		&:hover{
			text-decoration: underline;
		}
	}
}
/*---------banner-section--------------*/
.banner-section{
	position: relative;
	padding: 2.7rem 0;
	.main-container-post{
		position: relative;
		background-color: #FFD3B9;
		background-image: url(../img/bg_banner.jpg);
		background-repeat: no-repeat;	
		background-position: center;
		padding: 7.3rem 2rem;
		&:before{
			@include bg-banner();
			left: -100%;
		}
		&:after{
			@include bg-banner();
			right: -100%;
		}
	}
	&__row{
		text-align: center;
		width: 72rem;
		max-width: 100%;
		margin: 0 auto;
	}
	.index-title__h2{
		line-height: 6rem;
	}
}
/*------------company section-----------------*/
.company-section{
	padding: 8rem 0 7rem;
	&__row{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;
	}
	&__col{
		padding-top: 5rem;
		&:not(:last-child){
			padding-right: 2rem;
		}
	}
}
/*-----------content-text---------------------*/
.content-text{
	p{
		font: 400 1.8rem/3rem $base-font;
		color: #4B4D51;
		a{
			color: #FF8314;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	h3{
		position: relative;
		font: 600 2.4rem/3.5rem $base-font;
		margin: 4rem 0 3rem;
	}
	figure{
		margin: 5rem 0;
		img{
			margin-bottom: 2rem;
			width: 100%;
			height: auto;
		}
		figcaption{
			line-height: 2.5rem;
			color: #8A8E95;
		}
	}
	blockquote{
		box-sizing: border-box;
		margin: 5rem 0;
		padding: 5rem 4rem;
		border-top: .1rem solid #FF8314;
		border-bottom: .1rem solid #FF8314;
		p{
			font: 600 italic 3.6rem/5rem $base-font;
			margin-bottom: 5rem;
		}
		p+p{
			font: 500 2.2rem/2.6rem $base-font;
			margin-bottom: .5rem;
		}
		span{
			line-height: 2.5rem;
			color: #8A8E95;
		}
	} 
	.img-affect{
		margin: 5rem 0;
		padding: 1.5rem 1.5rem 2rem 1.5rem;
		box-shadow: 1.5rem 1.5rem 4.5rem rgba(197, 201, 205, 0.2);
		img{
			margin-bottom: 1.5rem;
			width: 100%;
			height: auto;
		}
		h3{
			margin: 0;
		}
	}
	.img-format{
		display: flex;
		align-items: center;
		margin-bottom: 3rem;
		div:last-child{
			font: 500 2.2rem/3.2rem $base-font;
		}
		img{
			margin-right: 2.5rem;
		}
	}
	ul{
		margin: 0 0 2rem 2.2rem;
		li{
			position: relative;
			font: 1.8rem/3rem $base-font;
			color: #4B4D51;
			padding-left: 1.8rem;
			margin-bottom: 2rem;
			a{
				color: #FF8314;
				&:hover{
					text-decoration: underline;
				}
			}
			&:before{
				top: 1.3rem;
				@include disk-ul();
			}
		}
	}  
}
/*-------content-terms----------------------------*/
.content-terms{
	background: white;
	padding: 10rem 0 13rem;
	.index-title__h1{
		font: 600 3.6rem/5rem $base-font;
	}
	&__row{
		width: 75rem;
		max-width: 100%;
		margin-left: 13%;
	}
	&__text{
		p{
			margin-bottom: 1.5rem;
		}
	}
}
/*-----------content-article-------------------------*/
.content-article{
	padding: 6rem 0 12rem;
	background: white;
	&__img{
		margin-bottom: 5.5rem;
		img{
			width: 100%;
			height: auto;
		}
	}
	&__row{
		width: 75rem;
		max-width: 100%;
		margin: 0 auto;
	}
	&__text{
		margin-bottom: 4rem;
		p{
			margin-bottom: 2rem;
			a{
				color: #FF8314;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		.block-navigation{
			display: flex;
			justify-content: space-between;
			padding: 3.5rem 4.5rem;
			background: #FAFAFA;
			border-radius: 3rem;
			margin: 4rem 0;
			>div{
				padding-right: 6%;
				>div{
					position: relative;
					padding-left: 2rem;
					&:before{
						@include disk-ul();
						top: 1rem;
					}
					&:not(:last-child){
						margin-bottom: 2rem;
					}
					a{
						font: 1.6rem/2.5rem $base-font;
						color: #4B4D51;
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
		}
		form{
			position: relative;
			background: #FFD3B9;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border-radius: 50px;
			box-sizing: border-box;
			padding: 4rem 8rem;
			margin: 5rem 0;
			h3{
				margin: 0 0 2rem;
			}
			.error-block{
				position: absolute;
				bottom: 9.8rem;
				left: 8.5rem;
			}
			input{
				padding-left: 2rem;
				border: .1rem solid #E0E0E0;
				font: 1.6rem $base-font;
				box-sizing: border-box;
				border-radius: 1rem;
				width: 31.6rem;
				height: 4.5rem;
				margin-bottom: 3rem;
			}
			p{
				margin-bottom: 2.5rem;
			}
		}
		h4{
			font: 500 2.2rem/2.6rem $base-font;
			color: #29304C;
			margin: 2rem 0 1.5rem;
		}
	}
	&__date{
		font: 1.4rem/1.7rem $base-font;
		color: #727B9D;
		margin-bottom: 6rem;
	}
	&__share{
		margin-bottom: 13rem;
		&-text{
			font: 600 2.4rem/3.5rem $base-font;
			color: #4B4D51;
			margin-bottom: 1.7rem;
		}
	}
	&__icons{
		margin-left: -.8rem;
		&-link{
			padding: .8rem;
			i{
				font-size: 1.8rem;
				color: #4B4D51;
			}
			&:hover i{
				color: #FF8314;
			}
		} 
	}
	&__posts-h3{
		font: 600 2.4rem/3.5rem $base-font;
		padding-bottom: 2rem;
		border-bottom: .1rem solid #1E1D2F;
		margin-bottom: 2rem;
	}
	&__posts-list-link{
		display: block;
		font: 1.8rem/3rem $base-font;
		color: #FF8314;
		margin-bottom: 1.5rem;
		&:hover{
			text-decoration: underline;
		}
	}
}
/*---------------404 page-------------------*/
.content-404{
	background: white;
	padding: 8rem 0 10rem;
	&__row{
		width: 55rem;
		max-width: 100%;
		margin: 0 auto;
		text-align: center;
	}
	&__text{
		font: 600 2.8rem/4.2rem $base-font;
		margin-bottom: 3rem;
	}
	.index-title__h1{
		font: 600 3.6rem/5rem $base-font;
		margin-bottom: 7rem;
	}
	&__img{
		margin-bottom: 3rem;
	}
	&__menu{
		display: flex;
		flex-direction: column;
		align-items: center;
		&-text{
			font: 500 2.2rem/2.6rem $base-font;
			color: #8A8E95;
			margin-bottom: 2rem;
		}
		&-list{
			display: flex;
			&-link{
				display: block;
				line-height: 2.6rem;
				color: #FF8314;
				margin-right: 3rem;
				&:hover{
					text-decoration: underline;
				}
			}
			&-item:last-child .content-404__menu-list-link{
				margin-right: 0;
			}
		}
	}
}
/*----------------faq page --------------------------*/
.content-faq{
	padding: 8rem 0 13rem;
	background: white;
	&__row{
		display: flex;
		align-items: flex-start;
		width: 123rem;
		max-width: 100%;
		margin: 0 auto;
	}
	&__text{
		h3{
			margin: 3rem 0 2rem;
		}
		figure, blockquote{
			margin: 4rem 0;
		}
		p{
			margin-bottom: 2rem;
		}
	} 
}
.block-faq__list{
	margin-bottom: 6rem;
	&-link{
		display: block;
		font: 1.8rem/3.8rem $base-font;
		color: #FF8314;
		text-decoration: underline;
	}
}
.block-faq__h1{
	margin-bottom: 3rem;
}
.block-aside{
	width: 32rem;
	margin-right: 6.5%;
	&__list{
		width: 32rem;
		box-shadow: .1rem .3rem 3rem rgba(234, 234, 234, 0.45);
		&-link{
			display: block;
			padding: 1.7rem 4rem;
			font: 500 1.8rem/2.2rem $base-font;
			color: #4B4D51;
			&:hover{
				background: #FF8314;
				color: white;
			}
		}
		.current-item > a{
			background: #FF8314;
			color: white;
		}
	}
}
/*--------------why section-----------------------*/
.why-section{
	padding-top: 13rem;
	&__col-title{
		min-height: 5.2rem;
	}
	&__text{
		margin-bottom: 5rem;
	}
}
/*-------------testimonials section---------------*/
.testimonials-section{
	padding-top: 0;
}
/*------------big banner section------------------*/
.big-banner-section .main-container-post{
	background-image: url(../img/big_bg_banner.jpg);
}
.big-banner-section .optimized-section__text{
	color: #4B4D51;
	margin-bottom: 2rem;
}
.big-banner-section__text{
	font: 500 2.2rem/2.6rem $base-font;
	color: #4B4D51;
	margin-bottom: 4rem;
}
/*--------------------popup-----------------------------*/
.block-popup{
	background: rgba(0, 0, 0, 0.6);
	width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    overflow: auto;
}
.wrap-block-form{
	position: relative;
	width: 112rem;
	box-sizing: border-box;
	margin: 6rem auto ;
	background: #FFFFFF;
	border-radius: 1.5rem;
	padding: 9rem 7rem 8.1rem;
	display: flex;
	max-width: 100%;
	justify-content: space-between;
	overflow: hidden;
	&:before{
		content: '';
		position: absolute;
		background: url(../img/img_form.jpg) no-repeat;
		width: 35.8rem;
		height: 28.3rem;
		bottom: -9rem;
		left: 0;
		z-index: 0;
	}
	.icon-cross{
		position: absolute;
		right: 7rem;
		font-size: 1.6rem;
		top: 5rem;
		cursor: pointer;
		&:hover{
			color: #FF8314;
		}
	}
}
.leftSide{
	position: relative;
	width: 49%;
	.index-section__paragraph{
		font-weight: 400;
		color: #4B4D51;
		margin-bottom: 1.1rem;
	}
	.index-title__h1{
		margin-bottom: 1.5rem;
	}
	&:before{
		content: '';
		position: absolute;
		background: url(../img/img_form2.png) no-repeat;
		width: 17rem;
		height: 7.5rem;
		bottom: -4rem;
		right: 0;
		z-index: 0;
	}
}
.rightSide{
	position: relative;
	width: 41%;
	.main-btn{
		padding: 1.2rem 4rem;
		&:last-child{
			margin-left: 1rem;
		}
	}
	.wrapper-btn{
		text-align: right;
		margin-top: 2rem;
	}
}
.wrap-select{
	display: flex;
}
.rightSelect, .leftSelect{
	display: flex;
	flex-direction: column;
	width: 19rem;
}
.leftSelect{
	margin-right: 2rem;
}
.label-form{
	margin-bottom: 1rem;
	font: 1.4rem/1.7rem $base-font;
	color: #4B4D51;
}
.form-select{
	background-image: url("../img/arrow_dropdown.svg");
	background-position: 91% 51%;
	background-size: 1.4rem auto;
	background-repeat: no-repeat;
	margin-bottom: 2rem;
}
.popup-form{
	display: flex;
	flex-direction: column;
	select, input{
		padding: 0 1.5rem;
		font-size: 1.6rem;
	}
}
.input-form{
	margin-bottom: 2rem;
	&:focus{
		border: .1rem solid #FF644F;
	}
}
/*-------------popup success--------------*/
.form-success{
	flex-wrap: wrap;
	align-items: center;
	&:before{
		display: none;
	}
	.leftSide{
		width: 48%;
		&:before{
			display: none;
		}
	}
	.rightSide{
		width: 45%;
	}
	.icon-cross{
		right: 3rem;
		top: 3rem;
	}
	&__list-link{
		font: 1.8rem/3rem $base-font;
		color: #FF8314;
		&:hover{
			text-decoration: underline;
		}
	}
	.wrapper-btn{
		margin-top: 10rem;
		width: 100%;
		text-align: center;
		.main-btn{
			padding: 1.4rem 6rem;
		}
	}
}
/*-----------format section ---------------*/
.format-section{
	padding-bottom: 8rem;
	&__h2{
		margin-bottom: 2.5rem;
	}
	&__row{
		width: 116rem;
		max-width: 100%;
		margin: 0 auto;
	}
}
/*----------web section ----------------*/
.web-section .optimized-section__text{
	margin-bottom: 0;
}
/*------section form---------------------*/
.index-section-form{
	background: white;
	padding: 5.5rem 0 8rem;
	.index-row{
		align-items: center;
	}
}
.block-form{	
	width: 54.6rem;
	box-sizing: border-box;
	padding: 4.8rem 7.3rem;
	box-shadow: .5rem .5rem 4rem #edeff2;
	background: white;
	margin-left: 2rem;
	&__text{
		text-align: center;
		font: 1.8rem/3rem $base-font;
		margin-bottom: 3.8rem;
	}
	&__title{
		text-align: center;
		margin-bottom: 1.5rem;
		font: bold 4.2rem/6.5rem $base-font;
	}
	&__wrap-btn{
		padding-top: 2rem;
		.main-btn--orange{
			font-size: 1.6rem;
			padding: 1.5rem 7rem;
		}
	}
	&__p{
		text-align: center;
		font: 1.8rem/3rem $base-font;
		color: #4B4D51;
		a:hover{
			text-decoration: underline;
		}
	}
	&__col{
		position: relative;
		margin-bottom: 2.5rem;
		&-text{
			font: 400 1.6rem/2.6rem $base-font;
			color: #748394;
		}
	}
}
.block-forgot{
	text-align: right;
	margin-top: -1.5rem;
	a:hover{
		text-decoration: underline;
	}
}
.main-input__label{
	&-text{
		display: block;
		font: 1.4rem/1.7rem $base-font;
		color: #4B4D51;
		margin-bottom: 1rem;
	}
	&-input{
		border: 1px solid rgba(177, 182, 189, 0.6);
		box-sizing: border-box;
		border-radius: 1rem;
		width: 100%;
		height: 4.5rem;
		font: 1.6rem/2.6rem $base-font;
		color: #4B4D51;
		padding: 0rem 4.5rem 0rem 2rem;
		&:focus{
			border: .1rem solid #FF8314;
		}
	}
	&-btn{
		position: absolute;
		right: 1.5rem;
		top: 4.2rem;
	}
}
.wrapper-form-forgot{
	margin: 0 auto;
}
.block-form-forgot{
	margin-left: 0;
}
/*-------------content-reference--------------------*/
.content-reference{
	background: white;
	padding-top: 5.4rem;
	.block-aside{
		float: right;
		margin: 0 0 0 4.5%;
		width: 33.8rem;
		&__list{
			width: 33.8rem;
			&-link{
				padding: 1.7rem 2.2rem 1.7rem 4rem;
			}
		}
	}
	&__paragraph{
		line-height: 2.6rem;
		margin-bottom: 2.6rem;
		color: #4B4D51;
	}
	&__text{
		width: calc(100% - 39rem);
		border-top: .3rem dashed #ECE8E8;
		h3:first-child{
			margin-top: 2.6rem;
		}
		p{
			font: 1.6rem/2.6rem $base-font;
			margin-bottom: 2.5rem;
		}
		ul{
			@include border-reference();
			margin: 0 0 3rem 0;
			padding: 1.5rem 2rem;
			li{
				padding-left: 0;
				margin-bottom: 0;
				font: 1.4rem/2.8rem $base-font;
				&:before{
					display: none;
				}
				span{
					color: #EA4747;
				}			
			}
		}
		>span{
			display: inline-block;
			margin-bottom: 1rem;
		}
	}
	.rectangle{
		@include border-reference();
		font-size: 1.4rem;
		padding: .2rem .8rem;
	}
}
.block-reference{
	width: calc(100% - 39rem);
}
.content-reference__list{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	&-link{
		display: block;
		border: .1rem solid #ECE8E8;
		border-radius: 4rem;
		box-sizing: border-box;
		padding: .6rem 1.6rem;
		color: #000000;
		margin-bottom: 2rem;
		&:hover{
			background: #FF8314;
			color: white;
			border-color: #FF8314;
		}
	}
	&-item:not(:last-child){
		margin-right: 1.2rem;
	} 
	.current-menu a{
		background: #FF8314;
		color: white;
		border-color: #FF8314;
	}
}
/*----------------developer-section--------------*/
.developer-section .index-section__paragraph{
	margin-bottom: 3rem;
}
/*----------started-section----------*/
.started-section{
	padding-top: 8rem;
	&__text{
		margin-bottom: 0;
		span{
			color: #FF9E55;
		}
	}
}
/*----------price-section------------*/
.price-section{
	padding-bottom: 13rem;
	&__row{
		width: 110rem;
		max-width: 100%;
		margin: 0 auto;
	}
	&__upload{
		width: 100%;
		margin-top: 4rem;
		&-title{
			padding: 1.8rem 2rem;
			background: #FFB048;
			font: 600 2rem $base-font;
			&:before, &:after{
				content: "";
				display: table;
				clear: both;
			}
		}
		&-first{
			display: inline-block;
			width: 60%;
			padding-right: 5%;
			float: left;
		}
		&-second{
			display: inline-block;
			float: left;
			width: 35%;
		}
	}
	&__list{
		padding: 1.5rem 2rem 0rem;
		background: #FFF2F2;
		font: 1.8rem/3.5rem $base-font;
		&-item{
			padding: 1rem 0;
			.clr-orange{
				font-weight: bold;
			}
			&:not(:last-child){
				border-bottom: .1rem solid #F4DADA;
			}
			&:before, &:after{
				content: "";
				display: table;
				clear: both;
			}
		}
	}
	&__calc{
		position: relative;
		width: 100%;
		margin-top: 4rem;
		box-sizing: border-box;
		padding: 1.5rem 2rem 3.5rem 2rem;
		background: #FFF2F2;
		border-radius: .4rem;
		overflow: hidden;
		&:before{
			content: '';
			position: absolute;
			right: -2.5rem;
			top: 0;
			background: url(../img/img_calc.png) no-repeat;
			width: 10.4rem;
			height: 12.8rem;
		}
		&:after{
			content: '';
			position: absolute;
			right: 0;
			bottom: -2.4rem;		
			background: url(../img/img_calc2.png) no-repeat;
			width: 21.6rem;
			height: 18.1rem;
		}
		&-title{
			font: 600 2rem/4rem $base-font;
			margin-bottom: .5rem;
		}
		&-compres, &-total{
			font: 1.8rem/3.5rem $base-font;
			strong{
				color: #FF8314;
			}
		}
		&-compres{
			margin-bottom: 1.5rem;
		}
		&-prices{
			position: relative;
			background: #FFFFFF;
			border-radius: 10rem;
			width: 62%;
			height: 2.8rem;
			margin-bottom: 3.5rem;
			cursor: pointer;
			.ui-slider-range{
				position: absolute;
				width: 37%;
				height: 100%;
				background: #FF8314;
				border-radius: 10rem 0rem 0rem 10rem;
			}
			.ui-slider-handle{
				position: absolute;
				z-index: 100;
				top: -.4rem;
				width: 2.2rem;
				height: 2.2rem;
				border-radius: 50%;
				background: #FF8314;
				border: .8rem solid #FFFFFF;
				margin-left: -1rem;
				box-shadow: 0 .2rem .7rem rgba(255, 131, 20, 0.24);
			}
		}
	}
}
/*--------pricing-section---------------*/
.pricing-section{
	padding: 5.4rem 0 8rem;
	&__text{
		text-align: center;
		font: 600 2.4rem/3.5rem $base-font;
		color: #637381;
	}
	&__h1{
		text-align: center;
	}
	&__row{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	&__col{
		position: relative;
		box-sizing: border-box;
		margin: 6rem 1.1% 0;
		padding: 4.5rem 2.7rem;
		background: white;
		width: 31%;
		box-shadow: .1rem .4rem 3rem rgba(218, 218, 218, 0.45);
		border-radius: .7rem;
		&-save{
			position: absolute;
			top: -1.6rem;
			left: 50%;
			transform: translate(-50%, 0);
			background: #FF4F4F;
			border-radius: 2.5rem;
			color: white;
			padding: .8rem 2rem;
			text-align: center;
		}
		&-save--white{
			background: #E8EBF0;
			color: #1E1D2F;
		}
		&-month{
			display: flex;
			justify-content: center;
			align-items: center;
			background: url(../img/back_price.png) no-repeat;
			width: 18.9rem;
			height: 6.3rem;
			margin: 0 auto .2rem;
			font: 500 1.8rem/2.2rem $base-font;
			color: #232D3A;
		}
		&-price{
			color: #91969C;
			text-align: center;
			margin-bottom: 5rem;
			span{
				font: bold 4.2rem/6.5rem $base-font;
				color: #000000;
				vertical-align: middle;
				margin: 0 .6rem;
			}
		}
		&-list{
			line-height: 2.6rem;
			color: #91969C;
			margin-bottom: 3.5rem;
		}
		&-item{
			position: relative;
			padding-left:3rem;
			margin-bottom: .5rem;
			&:before{
				content: '';
				position: absolute;
				left: 0;
				background: url(../img/icon-list.png) no-repeat;
				width: 2rem;
				height: 2rem;
				top: .4rem;
			}
		}
		.wrapper-btn{
			text-align: center;
		}
		.main-btn{
			font-size: 1.6rem;
		}
	}
}
/*---------alternativ-section--------------*/
.alternativ-section{
	padding-bottom: 8rem;
	&__h3{
		text-align: center;
		margin-bottom: 5rem;
	}
	&__h4{
		width: 100%;
		margin-bottom: 1rem;
	}
	&__pay{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		background: #FFFFFF;
		padding: 2.5rem;
		box-shadow: .1rem .4rem 3rem rgba(218, 218, 218, 0.45);
		border-radius: .7rem;
		&-list{
			margin-bottom: 0;
			display: flex;
			li{
				margin: 0 1.3rem 0 0;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
	.main-btn{
		font-size: 1.6rem;
		padding: 1.4rem 2.8rem;
	}
	&__plans{
		padding-top: 4rem;
		margin-top: 4.5rem;
		border-top: .1rem solid #ECE8E8;
		display: flex;
		align-items: center;
		&-list{
			display: flex;
		}
		.index-title__h4{
			margin-right: 5.4rem;
		}
		&-item{
			position: relative;
			color: #8A8E95;
			line-height: 2.6rem;
			padding-left: 4.5rem;
			&:not(:last-child){
				margin-right: 5.4rem;
			}
			&:before{
				content: '';
				position: absolute;		
				height: 2.35rem;
				left: 0;
				top: 0.1rem;
			}
			&:first-child::before{
				background: url(../img/icon_plans.png) no-repeat;
				width: 3rem;
			}
			&:nth-child(2)::before{
				background: url(../img/icon_plans2.png) no-repeat;
				width: 2.3rem;
			}
			&:last-child::before{
				background: url(../img/icon_plans3.png) no-repeat;
				width: 3.45rem;
			}
		}
	}
}
/*-----------------------developer dashboard--------------------------*/
.main-header-dev{
	background: white;
	padding: 1.7rem 0;
	border-bottom: .1rem solid #E9E9E9;
	transform: rotate(0.03deg);
	&__navigation{
		display: flex; 
		align-items: center;
		justify-content: space-between;
		padding-left: 18rem;
		width: 100%;
	}
	.block-logo{
		height: 2.6rem;
	}
}
.block-aside-dev{
	width: 38rem;
	box-shadow: .1rem .3rem 3rem rgba(234, 234, 234, 0.45);
	margin-right: 0;
	&__list{
		width: 38rem;
		box-shadow: none;
		&-link{
			position: relative;
			display: flex;
			align-items: center;
			padding: 1.7rem 2rem 1.7rem 4rem;
			>img{
				position: absolute;
				right: 2rem;
				top: 2rem;
			}
			&:hover{
				img{
					filter: brightness(0) invert(1);
				}
			}
			span{
				display: inline-block;
				width: 2.3rem;
				height: 2.3rem;
				margin-right: 1.5rem;
			}
		}
		.current-item .block-aside-dev__list-link img{
			filter: brightness(0) invert(1);
		}
		.sub-menu{
			display: none;
			padding: 3rem 0 3rem 12%;
			font: 500 1.8rem/2.2rem $base-font;
			&__item:not(:last-child){
				margin-bottom: 3rem;
			}
			&__link{
				position: relative;
				padding-left: 2rem;
				display: block;
				color: #8A8E95;
				&:before{
					content: '';
					position: absolute;
					left: 0;
					background: url(../img/icon_sub_menu.svg) no-repeat;
					width: 1.1rem;
					height: 1.1rem;
					top: .5rem;
				}
			}
		}
	}
}
.dev-section__wrap{
	display: flex;
	min-height: 100vh;
}
.column-dev-right{
	width: 100%;
	background: #F6F6F6;
	padding: 4rem 0 20rem;
	&__select{
		float: right;
		margin-top: -9rem;
		select{
			padding: 0 1rem;
			font-size: 1.6rem;
			height: 4rem;
			border-radius: .5rem;
			border: none;
			box-shadow: 0 .1rem .3rem rgba(63, 63, 68, 0.15), 0 0 0 rgba(63, 63, 68, 0.05);
		}
		.rightSelect, .leftSelect{
			width: 18rem;
		}
	} 
	.index-title__h3{
		margin-bottom: 2rem;
	}
	.wrap-table{
		overflow: auto;
		margin-bottom: 4rem;
		width: 100%;
		@include wrap-dev();
	}
	.table-dev{
		width: 100%;
		tr{
			border-bottom: .1rem solid #DFE3E8;
		}
		th, td{
			text-align: left;
			vertical-align: middle;
			box-sizing: border-box;
			color: #8A8E95;
		}
		td{
			padding: 1.1rem 2.7rem;
		}
		th{
			padding: 2.1rem 2.7rem;
			color: #4B4D51;
		}
		&__upload{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 3.7rem;
			height: 3.7rem;
			border-radius: 50%;
			box-sizing: border-box;
			border: .2rem solid #ECE8E8;
			&:hover{
				background: #FF8314;
				border: 2px solid #FF8314;
				img{
					filter: brightness(0) invert(1);
				}
			}
		}
		&__status{
			display: flex;
			margin: 0 auto;
			justify-content: center;
			align-items: center;
			background: #ECE8E8;
			border-radius: 50%;
			width: 3rem;
			cursor: pointer;
			height: 3rem;
			i{
				color: white;
			}
			&:hover{
				background: #FF8314;
			}

		}
		.status-success{
			background: #FF8314;
		}
		.column-general{
			text-align: center;
			padding: 3.5rem 2.7rem;
		}
	}
	&__p{
		color: #8A8E95;
		margin: -1.5rem 0 2.5rem;
	}
	.develop-table{
		tr:last-child td{
			padding: 2.2rem 2.7rem ;
			color: #1E1D2F;
		}
		.column-4{
			width: 52%;
		}
		.column-5{
			width: 10%;
		}
	} 
	.table-billing{
		border-radius: .5rem;
		box-shadow: 0 .1rem .3rem rgba(63, 63, 68, 0.15), 0 0 0 rgba(63, 63, 68, 0.05);
		td{
			padding: 2.1rem 2.7rem;
		}
		.column-1{
			color: #4B4D51;
		}
		.column-2{
			a{
				display: none;
			}
		}
		.column-3{
			color: #4B4D51;
			width: 65%;
		}
		.column-4{
			text-align: right;
		}
		a{
			color: #FF8314;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.table-keys{
		td{
			padding: 1.3rem 2.7rem;
		}
		.input-table{
			background: #F9F9F9;
			padding: 1.1rem;
			font: 1.6rem/2.6rem $base-font;
			border-radius: .4rem;
			height: 3.6rem;
			&:focus{
				background: #F9F9F9;
				border: .1rem solid #FF8314;
				border-radius: .4rem;
				box-sizing: border-box;
			}
			&::placeholder{
				opacity: 1;
			}
		}
		.label-input{
			width: 30rem;
			color:  #FF8314;
			&:focus{
				padding: 1rem;
			}
		}
		.key-input{
			width: 34.5rem;
			color: #8A8E95;
			&:focus{
				padding: 1.1rem;
			}
		}
		.column-5{
			width: 5%;
		}
		.column-3 a{
			font-size: 1.4rem;
			color: #FF8314;
			display: none;
		}
		a{
			color: #FF8314;
			&:hover{
				text-decoration: underline;
			}
		}
	}
}
.table-dev .optimized-section__line-progress{
	width: 10rem;
}
.table-dev__btn{
	color: #8A8E95;
	border: .2rem solid #ECE8E8;
	padding: .9rem 3rem;
	text-transform: none;
}
.dev-section__attention{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 0 6.5rem;
	box-sizing: border-box;
	border-radius: .7rem;
	padding: 1.8rem 2rem;
	.main-btn--orange{
		margin-right: 1rem;
	}
	&-text{
		position: relative;
		padding-left: 4.5rem;
		&:before{
			content: '';
			position: absolute;
			left: 0;
			width: 2.9rem;
			height: 2.9rem;
		}
	} 
	p{
		line-height: 2.6rem;
	}
}
.block-attention{
	border: .1rem solid #FF8314;
	background: #FEF2E7;
	&__text::before{
		background: url(../img/icon_attention.svg) no-repeat;
	}
}
.block-success{
	background: rgba(111, 207, 151, 0.2);
	border: .1rem solid #27AE60;
	margin-bottom: 3rem;
	&__text::before{
		background: url(../img/icon_success.svg) no-repeat;
	}
}
.manual-optimization{
	padding: 1.3rem 2.3rem 2rem ;
	@include wrap-dev();
	margin-bottom: 4rem;
	&__list{
		display: flex;
		flex-wrap: wrap;
		line-height: 3.6rem;
		margin-bottom: 2.5rem;
		&-item:not(:last-child){
			margin-right: 5rem;
		}
		&-link{
			position: relative;
			display: block;
			color: #8A8E95;
			&:hover::before{
				content: '';
				position: absolute;
				width: 100%;
				bottom: 0;
				border-bottom: .4rem solid #FF8314;
			}
		}
		.item-active &-link::before{
			content: '';
			position: absolute;
			width: 100%;
			bottom: 0;
			border-bottom: .4rem solid #FF8314;
		}
	}
	.wrapper-btn{
		margin-top: 2rem;
		text-align: center;
	}
}
.dev-optimized__line-col{
	box-sizing: border-box;
	padding: .7rem 2rem;
	background: #F6F6F6;
	border-radius: .5rem;
	margin-bottom: 1rem;
}
.dev-optimized__line-after{
	width: 49%;
}
.dev-optimized__line-progress{
	width: 20%;
	overflow: hidden;
}
.dev-optimized__line-text{
	width: 30%;
	font-size: 1.6rem;
}
.dev-optimized{
	text-align: center;
	.main-btn{
		margin-top: 1rem;
	}
}
.dev-target{
	position: relative;
	text-align: center;
	padding: 2.6rem 0;
	border-radius: .5rem;
	border: .1rem dashed #DFE3E8;
	margin-bottom: 2rem;
	width: 100%;
	&__text{
		line-height: 2.6rem;
		color: #8A8E95;
	}
	&__img{
		margin-bottom: 1rem;
	}
}
.block-questions{
	@include wrap-dev();
}
.block-question{
	position: relative;
	padding: 1.9rem 2.2rem;
	cursor: pointer;
	&:before{
		content: '#{$icon-plus}';
		position: absolute;
		font-family: '#{$icomoon-font-family}';
		color: #637381;
		right: 2.2rem;
		top: 2rem;
	}
	&:not(:last-child){
		border-bottom: .1rem solid #DFE3E8;
	}
	&__text{
		display: none;
		line-height: 2.6rem;
		color: #637381;
		margin-top: 2rem;
	}
	&:hover{
		background: #F6F6F6;
	}
}
.question-active::before{
	content: '#{$icon-minus}';
}
/*---------------api keys-------------------------*/
.api-row{
	display: flex;
	justify-content: space-between;
}
.api-col{
	margin-top: 5rem;
	width: 49%;
	padding: 2.9rem 2.4rem;
	@include wrap-dev();
	&__text{
		line-height: 2.6rem;
		color: #8A8E95;
		margin-bottom: 2.5rem;
	}
}
/*-----------------popup api key---------------------*/
.new-form{
	max-width: 100%;
	position: relative;
	margin: 5rem auto 0;
	background: #FFFFFF;
	border: .1rem solid #FBFBFB;
	box-sizing: border-box;
	box-shadow: 0px .4rem 3.5rem rgba(107, 109, 116, 0.25);
	border-radius: 1.5rem;
	&__top{
		padding: 2.5rem 3rem;
	}
	&__center{
		padding: 2rem 3rem;
		border-top: .1rem solid #ECE8E8;
		border-bottom: .1rem solid #ECE8E8;
	}
	&__h2{
		font: 600 2.2rem/3rem $base-font;
		margin-bottom: 0 !important;
		text-align: left;
	}
	&__h3{
		font: 500 1.8rem/2.2rem $base-font;
		margin-bottom: 1.5rem;
	}
	&__link{
		display: block;
		text-decoration: underline;
		font-size: 1.4rem;
		color: #FF8314;	
		margin-bottom: 1.5rem;
		word-wrap: break-word;
	}
	&__text{
		font: 1.4rem/2.5rem $base-font;
		color: #8A8E95;
	}
	.wrapper-btn{
		padding: 2rem 3rem 3rem;
		.main-btn--orange{
			margin-right: 1rem;
		}
	}
	.close-popup{
		cursor: pointer;
		position: absolute;
		right: 3rem;
		top: 3.2rem;
		color: #8A8E95;
		&:hover{
			color: #FF8314;
		}
	}
}
.block-api{
	width: 50.3rem;
}
/*--------------billing info---------------------*/
.billing-info-row{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.billing-info-col{
	@include wrap-dev();
	width: 49%;
	padding: 2.2rem 2.2rem 2.4rem;
	line-height: 2.6rem;
	&:not(:last-child){
		margin-bottom: 2.5rem;
	}
	.index-title__h3{
		margin-bottom: 0rem;
	}
	.wrap-title{
		margin-bottom: 2rem;
	}
	&__data{
		margin-bottom: 3.5rem;
	}
	&__link{
		text-decoration: underline;
		color: #FF8314;
		&:hover{
			text-decoration: none;
		}
	}
	&__form{
		margin-top: 2rem;
	}
}
.block-checkbox{
	input[type=checkbox] {
		display: none;
		&:checked + label::before{
			content: '#{$icon-check}';
			font: 1.8rem/2.8rem $icomoon-font-family;
			color: white;
			border: .1rem solid #FF8314;
			background: #FF8314;
			text-align: center;
		}
	}
	label{
		display: inline-block;
		cursor: pointer;
		position: relative;
		padding-left: 4.2rem;
		&:before{
			content: "";
			position: absolute;
			width: 2.8rem;
			height: 2.8rem;
			border: .1rem solid #ECE8E8;
			border-radius: .4rem;
			left: 0;
			top: 0;
		}
		span{
			display: block;
		}
	}
	&:not(:last-child){
		margin-bottom: 3rem;
	}
	&__title{
		margin-bottom: .5rem;
	}
	&__text{
		color: #8A8E95;
	}
}
.table-payment{	
	border: .1rem solid #DCDCDC;
	box-sizing: border-box;
	border-radius: .5rem;
	margin-bottom: 2.2rem;
	&__block{
		display: flex;
		justify-content: space-between;
		padding: 2.2rem 1.8rem;
		&:not(:last-child){
			border-bottom: .1rem solid #DCDCDC;
		}
		&-text{
			position: relative;
			padding-left: 5.7rem;
			&:before{
				content: '';
				position: absolute;
				left: 0;
				background: url(../img/img_method.jpg) no-repeat;
				width: 4.3rem;
				height: 2.6rem;
			}
		}
	}
	&__link{
		color: #FF8314;
		&:hover{
			text-decoration: underline;
		}
	}
}
/*----------dev_billing_info-------------------------------*/
.dev-billing-info{
	@include wrap-dev();
	padding: 2.2rem;
	.block-checkbox label{
		padding: .5rem 0 0 4.2rem;
	}
	.main-btn--orange{
		margin-right: 1rem;
	}
}
.billing-form{
	display: flex;
	justify-content: space-between;
	&__wrap{
		display: flex;
		flex-direction: column;
		width: 48%;
	}
	.left-column, .right-column{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 48%;
	}
	.wrap-provide{
		width: 100%;
	}
	.label-form{
		font: 500 1.8rem/2.2rem $base-font;
		margin-bottom: 0;
	}
	&__text{
		line-height: 2.6rem;
		color: #8A8E95;
		margin-bottom: .5rem;
	}
	select, input{
		padding: 0 1.5rem;
		font-size: 1.4rem;
		color: #8A8E95;
	}
	.form-select{
		background-position: 94% 51%;
	}
}
/*----------------dev-billing-update---------------*/
.dev-billing-update{
	width: 72.3rem;
	.block-checkbox__title{
		font-size: 1.4rem;
	}
	.block-checkbox:not(:last-child){
		margin-bottom: 2rem;
	}
	.billing-form{
		flex-direction: column;
		.left-column, .right-column{
			width: 100%;
		}
		.wrap-provide input{
			margin-bottom: 0;
		}
		.label-form, &__text{
			font-size: 1.4rem;
		}
	} 
	.block-checkbox label{
		padding: .5rem 0 0 4.2rem;
	}
}
/*------update billing email--------------------*/
.update-billing-email{
	width: 50.3rem;
	.billing-form__wrap{
		width: 100%;
	}
	.billing-form__text, .billing-form .label-form{
		font-size: 1.4rem;
	}
	.input-form{
		margin-bottom: 1rem;
	}
}
/*-------------------billing plan------------------*/
.billing-info-col__plan{
	margin-top: 2.5rem;
	padding: 1.8rem 1.6rem;
	background: #ECE8E8;
	border-radius: .5rem;
	a{
		text-decoration: underline;
		color: #1E1D2F;
		&:hover{
			text-decoration: none;
		}
	}
}
.info-plan-right{
	width: 25%;
	.billing-info-col{
		width: 100%;
		padding: 0;
		margin-bottom: 1.5rem;
	}
	.new-form__top{
		position: relative;
		padding: 2rem 1.9rem 6.2rem 2.5rem;
		border-bottom: .1rem solid #ECE8E8;
		&:before{
			content: '';
			position: absolute;
			right: 1.9rem;
			bottom: 1.9rem;
			background: url(../img/img_method.jpg) no-repeat;
			width: 4.3rem;
			height: 2.6rem;
		}
	}
	.wrapper-btn{
		padding: 1.5rem;
	}
	.main-btn{
		width: 100%;
		padding: 1.4rem;
		text-align: center;
	}
}
.info-plan-left{
	width: 73%;
	.block-checkbox label span{
		width: 70%;
	}
}
.billing-wrap{
	position: relative;
	&:not(:last-child){
		margin-bottom: 3.5rem;
	}
	&__price{
		position: absolute;
		right: 0;
		top: 0;
		font-weight: 500;
		padding: .5rem 1.5rem;
		background: #ECE8E8;
		border-radius: .7rem;
	}
	&__account{
		vertical-align: .3rem;
		padding: .5rem 2rem;
		background: #FF8314;
		border-radius: 2.8rem;
		color: white;
		font: 500 1.6rem/3.5rem $base-font;
	}
}
.chage-plan{
	margin-top: 1.5rem;
	background: #FFD3B9;
	border-radius: .5rem;
	padding: 1.7rem 2.2rem 2.1rem;
	&__text{
		line-height: 2.6rem;
		margin-bottom: 2.4rem;
	}
}
/*------------developer plan----------------*/
.column-dev-plan__title{
	text-align: left;
	margin-bottom: 2.3rem;
}
.navigation-plans{
	justify-content: flex-end;
}
.circle-wrap{
	position: relative;
	display: flex;
	align-items: center;
	padding-right: 2.5rem;
	&__photo{
		display: inline-block;
		width: 4.8rem;
		border-radius: 50%;
		height: 4.8rem;
		background: #ECE8E8;
		overflow: hidden;
		margin-right: 1rem;
	}
	&__text{
		font: 500 1.8rem/3rem $base-font;
		color: #8A8E95;
	}
	&:before{
		content: '';
		position: absolute;
		background: url("../img/dropdown_nav.svg");
		width: 1.5rem;
		height: .8rem;
		right: 0;
		top: 2rem;
	}
}





