.color-blue{
	color: #0AAFFF;
}
.clr-orange{
	color: #FF8314;
}
.clr-red{
	color: #EB5757 !important;
}
.light-green{
	color: #57B892 !important;
}
.figure{
	position: absolute;
	z-index: 10;
}
.figure-banner-one{
	left: 38%;
	bottom: 0;
}
.figure-banner-two{
	left: 61.8%;
	top: 0;
}
.figure-banner-three{
	left: 28%;
	top: 55%;
}
.figure-banner-four{
	left: 21.9%;
	top: 20%;
}
.figure-banner-five{
	left: 65%;
	top: 60%;
}
.banner-figure__one{
	left: 35%;
	top: 69%;
}
.banner-figure__two{
	left: 39%;
	top: 34%;
}
.slate-gray{
	color: #748394 !important;
}
.has-error{
	border: 1px solid #F50C0C !important;
}
.has-success{
	border: 1px solid #5D8ED6 !important;
}
.has-error__text{
	font: 600 1.4rem/2.3rem $base-font;
	color: #F50C0C;
	position:absolute;
	text-align: right;
	margin-bottom: -2.3rem;
	right: 0;
}