.main-btn{
	display: inline-block;
	padding: 1.4rem 4.3rem;
	border-radius: 2.8rem;
	box-sizing: border-box;
	cursor: pointer;
	border: 2px solid #FF8314;
	text-transform: uppercase;
	outline: none;
	color: #FF8314;
	font: bold 1.2rem $base-font;
	&:hover{
		color: white;
		background: #FF8314;
		border: 2px solid #FF8314;
	}
	&:focus{
		background: #F5740B;
		border: 2px solid #F5740B;
		color: white;
	}
}
.main-btn--small{
	padding: 1.5rem 5.5rem;
	font: 800 2.4rem/1.25 $base-font;
	letter-spacing: 0.004em;
}
.main-btn--orange{
	background: #FF8314;
	color: white;
	border: none;
	border: 2px solid #FF8314;
	&:hover{
		background: white;
		color: #FF8314;
		border: 2px solid #FF8314;
	}
	&:focus{
		background: #F5740B;
		border: 2px solid #F5740B;
		color: white;
	}
}
.main-btn--blue{
	background: #0AAFFF;
	color: white;
	border: none;
	&:hover, &:focus{
		border: none;
	}
}
.features-section__wrapper-btn{
	text-align: center;
	margin-top: 8rem;
}
.block-circle{
	position: relative;
	width: 6rem;
	height: 6rem;
	border: .2rem solid #FFC002;
	box-sizing: border-box;
	box-shadow: 3rem .4rem 7rem rgba(16, 86, 122, 0.12);
	border-radius: 50%;
	margin-right: 1.5rem;
}
.section__wrap-btn{
	padding: 8rem 0 2.3rem;
	text-align: center;
}

